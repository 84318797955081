<template>
    <div class="body">
        <img src="../assets/showpics/biopic.png" width="100%" @click="() => { showModal = true }" class="bio-pic" :class="{ 'dark': darkMode }">
        <h1>BIO</h1>
        <div class="bio" :class="{ 'dark': darkMode }">
            <p>Prosperity Gospel was founded by Eddie Newman in 2020. It has been described as post black metal and post
                hardcore among other genres. The music is atmospheric and dark; the lyrics mainly deal with philosophy
                and
                the
                horrors of late capitalism.</p>

            <p>The first record “Violently Pulled From Bliss” was released March 31st 2021, recorded and performed
                entirely
                by E
                Newman. It is available on CD and digital distribution.</p>

            <p>The second record, “Society of the Spectral,” was released November 30, 2023, recorded and performed by D
                Josey,
                E Newman, M Bauknight, & M Lambert. It is available on 12″, cassette, and digital distribution.</p>
        </div>
        <h1>PRESS</h1>
        <div v-for="article in articles" v-bind:key="article" class="article" :class="{ 'dark': darkMode }">
            <a :href="article.src" target="_blank" rel="noreferrer noopener">
                <h3><span style="text-decoration: underline;">{{ article.pub }}: {{ article.title }}</span></h3>
            </a>
            <p style="font-style: italic;">{{ article.snippet }}</p>
        </div>
        <PhotoModal v-if="showModal" @close="showModal = false"><img src="../assets/showpics/biopic.png"></PhotoModal>

    </div>
</template>

<script>
import PhotoModal from './PhotoModal.vue'

export default {
    name: 'BioComp',
    components: {
        PhotoModal
    },
    props: ['darkMode'],
    data() {
        return {
            showModal: false,
            articles: [
                { "pub": "Hornthrowers", "title": "Prosperity Gospel – Society of the Spectral – Album Review and Q&A", "src": "https://hornthrowers.com/prosperity-gospel-society-of-the-spectral-album-review-and-qa", "snippet": "This aggressively progressive sound is paired with scathing anti capitalist critiques[...]" },
                { "pub": "IDIOTEQ", "title": "A sojourn through shadows: PROSPERITY GOSPEL’s “Society of the Spectral”", "src": "https://idioteq.com/a-sojourn-through-shadows-prosperity-gospels-society-of-the-spectral/", "snippet": "Eddie Newman’s reflections on Debord’s seminal work resonate with a chilling clarity, painting a sonorous landscape that is not just heard, but felt—a symphony of the spectral." },
                { "pub": "Free Times", "title": "Columbia band Prosperity Gospel howls ‘anti-Fascist post-black metal’ on sophomore album", "src": "https://www.postandcourier.com/free-times/music/music_features/columbia-metal-prosperity-gospel-album/article_6a9b7078-8f00-11ee-8681-43b802e6f304.html", "snippet": "Singer/songwriter and guitarist Eddie Newman shrieks and growls as grinding guitar riffs churn and the rhythm section coils and uncoils like a serpent." },
                { "pub": "Post & Courier", "title": "Best of SC Music 2023: Albums that missed the cut", "src": "https://www.postandcourier.com/charleston_scene/best-of-sc-music-2023-albums-that-missed-the-cut/article_b694c22e-a65a-11ee-8a0c-2b135d65f67c.html", "snippet": "[M]uch of the appeal derives from how much melody and texture is used to accentuate the ferocious riffage and guttural hollers. Too, the lyrics, all full of social critique and existential angst, match well with the music and are far more legible than is typical of the genre." },
            ]
        }
    },
    methods: {
    }

}
</script>

<style scoped>
.article {
    width: 100%;
    margin: auto;
    padding: 10px;
    border: solid black 2px;
    margin-bottom: 10px;
    background-color: mistyrose;
    transform: translateX(-10px);
}

.bio {
    border: solid black 2px;
    width: 100%;
    padding: 10px;
    transform: translateX(-10px);
    background-color: mistyrose;
}

.bio-pic {
    padding: 10px;
    transform: translateX(-10px);
    cursor: pointer;
    border: solid black 2px;
    background-color: mistyrose;
}
.article.dark,.bio.dark,.bio-pic.dark {
    background-color: #4b6c8e;
    border-color: white;
}

.body {
    width: 80%;
    margin: auto;
    max-width: 900px;

}
</style>