<template>
    <div class="body">
        <h1> "SOCIETY OF THE SPECTRAL" OUT NOW!</h1>
        <iframe style="border: 0; width: 350px; height: 754px;"
            src="https://bandcamp.com/EmbeddedPlayer/album=4116870700/size=large/bgcol=ffffff/linkcol=333333/package=1995729555/transparent=true/campaign=pg-website/"
            seamless><a href="https://prosperity-gospel.bandcamp.com/album/society-of-the-spectral">Society of the
                Spectral by PROSPERITY GOSPEL</a></iframe>
        <h1>CONTACT</h1>
        <div class="contact" :class="{ 'dark': darkMode }">
            <p>EMAIL: <a href="mailto:PROSPERITYGOSPEL@FUZZ.SCIENCE">PROSPERITYGOSPEL@FUZZ.SCIENCE</a></p>
            <p>INSTAGRAM: <a href="https://www.instagram.com/prosperity___gospel/" target="_blank"
                    rel="noreferrer noopener">instagram.com/prosperity___gospel/</a></p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LandingComp',
    props: ['darkMode'],
    data() {
        return {
        }
    },
    methods: {
    }

}
</script>

<style scoped>
.contact {
    border: solid black 2px;
    width: 60%;
    padding: 10px;
    background-color: mistyrose;
    margin: auto;
    overflow-wrap: break-word;
}
.contact.dark{
    background-color: #4b6c8e;
    border-color: white;
}
.body {
    width: 80%;
    margin: auto;
    max-width: 900px;
}
</style>